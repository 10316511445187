<template>
  <div
    v-if="showLogout"
    ref="confimration_modal"
    :class="{ show: showLogout === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <transition-group name="slide" appear>
          <div class="modal__header mb-3" key="header">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Automatic Logout
              </span>
            </div>
          </div>
          <div class="modal__content" key="content">
            <div class="modal__body">
              <h5 class="modal__head__title mb-2">
                The system has detect change(s) on your user access and would
                automatically log you out so you can access those change(s).
                Click Ok to continue.
              </h5>
              <!-- <div>
                <BaseInput
                  label="Username"
                  placeholder="Username"
                  :value="username"
                  type="text"
                  disbaled
                >
                </BaseInput>
                <BaseInput
                  label="password"
                  placeholder="Password"
                  v-model="password"
                  type="password"
                >
                </BaseInput>
              </div> -->
              <div class="d-flex mt-5">
                <!-- <button
                  data-dismiss="#confirmModal"
                  class="
                    button form__button form__button--lg
                    ml-2
                    save-changes
                  "
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button> -->
                <button
                  data-dismiss="#confirmModal"
                  style="width: 100px"
                  class="button form__button form__button--sm ml-2 w-30 save-changes"
                  @click="logoutUser"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import { LOGOUT } from "@/core/services/store/types";
import { mapState } from "vuex";
// import { required } from "vuelidate/lib/validators";
// import BaseInput from "@/components/Inputs/_base-Input.vue";

export default {
  name: "AutoLogOut",
  props: {
    warningMessage: Boolean,
    close: Function,
  },
  components: {
    // BaseInput,
  },
  computed: {
    ...mapState({
      // username: (state) => state.auth?.user?.userName || ""
    }),
    showLogout() {
      return this.warningMessage;
    },
  },
  data() {
    return {
      events: ["click", "mousedown", "mousemove", "scroll", "keypress", "load"],
      password: "",
      loading: false,
    };
  },
  watch: {
    warningMessage() {
      this.warningMessage;
    },
  },
  methods: {
    logoutUser() {
      this.$store.dispatch(LOGOUT);
      this.$emit("close");
      this.$router.push("/auth/login");
    },
  },
};
</script>
<style></style>
